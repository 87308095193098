import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/header"
import Subscribe from "../components/subscribe"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return(
  <>
    <Header />
    <Layout>
    <section id="styles"
          style= {{
            padding: '90px 0 72px',
            background: '#fff'
          }}
        >
            <div className="row section-head">
                <h1>{frontmatter.title}</h1>
                <h5>{frontmatter.date}</h5>
            </div>
    <div className="row add-bottom">
      <div className="add-bottom">
        <div
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </div>
    </section>
    <Subscribe />
    </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`